import {FC, useCallback, useContext, useState} from "react";
import {DevLog} from "@plumeuk/shapeshift-common/common";
import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {ModuleCompleteButton, ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {ScormDataProvider} from "@plumeuk/shapeshift-common/providers";
import {APIState, INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {ScormPlayer} from "./ScormPlayer";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {ScormSidebarControl} from "./ScormSidebarControl";
import {useDebounce} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((theme) => ({
	scormPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	scormCompleteWrapper: {
		zIndex: 102,
		position: "absolute",
		padding: "0 20px",
		bottom: "10px",
		left:0,
		right: 0,
		[theme.breakpoints.up("sm")]: {
			left: "auto",
			right: "40px",
			bottom: "40px"
		}
	},
	scormCompleteBtn: {
		float: "right",
		background: theme.palette.secondary.main,
		border: "none",
		"&:hover": {
			background: theme.palette.secondary.main
		}
	},
	iframe: {
		zIndex: 100,
		position: "absolute",
		width: "100%",
		height: "100%",
		border: "none",
		top: 0,
		bottom: 0,
		right: 0,
		left: 0
	}
}));


export const ScormPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: scormSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const [hasCompleted, setHasCompleted] = useState(false);
	const hasCompletedDebounced = useDebounce(hasCompleted, 450);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();

	const handleOnComplete = useCallback(() => {
		if(!hasCompleted) {
			setHasCompleted(true);
			notify(null, "Lesson completed", INotificationSeverity.success, 5000, false);
		}
	}, [hasCompleted, navigate, notify, scormSlug, curriculumDispatch]);

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000, false);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "scorm", slug: scormSlug as string}, status})
			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000, false);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000, false);
			}
		}
	}, [curriculumDispatch, scormSlug])

	const onFinish = useCallback((): void => {
		DevLog("onFinish");
	}, []);

	return (
		<Box className={classes.scormPage}>
			<ScormDataProvider courseSlug={courseSlug} scormSlug={scormSlug} curriculum={curriculum}>
				{({scorm, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{scorm?.fileFolder && <>
							<ScormPlayer
								manifestUrl={`/scorm-uploads/${scorm?.fileFolder}/imsmanifest.xml`}
								className={classes.iframe}
								onComplete={handleOnComplete}
								onFinish={onFinish}
								scorm={scorm}
								courseSlug={courseSlug}
							/>
							{hasCompletedDebounced && courseSlug && scormSlug &&
								<Box className={classes.scormCompleteWrapper}>
									<ModuleCompleteButton
										courseSlug={courseSlug}
										moduleSlug={scormSlug}
										type="scorm"
										className={classes.scormCompleteBtn}
										onApiUpdate={handleCompletion}
									>{() => <>Next <ArrowForwardIcon sx={{position: "relative", right: "-14px", fontSize: 30, margin: 0, padding: 0}}/></>}</ModuleCompleteButton>
								</Box>
							}
						</>}
						<ScormSidebarControl scorm={scorm} />
					</>}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
				</>}
			</ScormDataProvider>
		</Box>
	);
}