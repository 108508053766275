import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersQuizzesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminHeader, AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {GridColDef} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((_theme) => ({
	adminQuizzesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"quiz", IAdminUsersQuizzesResponseEntry>["columns"] => ([
	{
		field: "title",
		flex: 1,
		headerName: "Quiz",
		width: 200,
		type: "string"
	},
	{
		field: "languages",
		flex: 1,
		headerName: "Languages",
		filterable: false,
		type: "string",
		valueGetter: ({value}: {value: IAdminUsersQuizzesResponseEntry["languages"]}) =>
			value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	},
	{
		field: "createdAt",
		flex: 1,
		headerName: "Created",
		filterable: true,
		width: 100,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		flex: 1,
		headerName: "Published",
		width: 100,
		filterable: true,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminQuizzesList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminQuizzesList}>
			<AdminHeader
				title="Quizzes"
				subtitle="Manage quizzes"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"quiz", IAdminUsersQuizzesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/quiz"
				columns={headCells(locales.data)}
				enableDelete
				type="quiz"
				onEditAction={e => navigate(e.id.toString())}
			/>
		</Box>
	);
}