import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {RegisterForm, DynamicContainer, LoginNav} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";

const logo = "/v2Logo.svg"
const feather = "/PlumeFeather.svg"

const useStyles = makeStyles()((theme) => ({
	registerPage:{
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		minHeight: "100%"
	},
	imgFeather:{
		position: "fixed",
		bottom: "-10px",
		right: "-10px",
		pointerEvents: "none"
	}
}));

export const RegisterPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if(authenticated) navigate("/my-courses")
	}, [authenticated])

	return (
		<Box className={classes.registerPage}>
			<LoginNav
				logo={logo}
				onBackAction={() => navigate("/")}
				//onRegisterAction={() => navigate("register")}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				<RegisterForm
					title="Register to Plume Academy"
					onRegister={() => navigate("my-courses")}
				/>
			</DynamicContainer>
			<Box className={classes.imgFeather}>
				<img src={feather} />
			</Box>
		</Box>
	);
}