import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Drawer, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {PageBaseContext} from "../../contexts/pageBaseContext";
import {IconContext} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()((theme) => ({
	sideMenu: {
		width: "225px",
		"[class*=' MuiDrawer-paper']": {
			paddingTop: "0 !important"

		}
	},
	drawer: {
		width: "225px !important",
		height: "100%",
		transition: "all .3s ease-in-out",
		"& .MuiDrawer-paper": {
			position: "relative",
			height: "100%",
			paddingTop: theme["toolbarHeight"],
			transition: "all .3s ease-in-out",
			width: "225px",
			zIndex: theme.zIndex.appBar - 1,
			boxSizing: "border-box"
		}
	},
	header: {
		width: "223px",
		boxSizing: "border-box",
		padding: "16px 20px",
		borderBottom: "1px solid " + theme.palette.grey[400]
	},
	companyName: {
		fontSize: "14px",
		fontWeight: 700
	},
	subtitle: {
		fontSize: "12px"
	},
	links: {
		"& a": {
			textDecoration: "none",
			color: theme.palette.text.primary
		},
		padding: "24px 20px",
		display: "flex",
		flexDirection: "column",
		gap: "25px"
	},
	link: {
		cursor: "pointer",
		fontSize: "14px",
		fontWeight: 400,
		display: "flex",
		gap: "10px",
		"& svg": {
			width: "16px"
		}
	},
	activeLink: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		"& path": {
			fill: theme.palette.primary.main
		}
	},
	sectionTitle: {
		fontSize: "11px",
		fontWeight: 700
	}
}));

export const AdminSideMenuContent: FC = () => {
	const {cx, classes} = useStyles();
	const location = useLocation();
	const [pageBaseContext] = useContext(PageBaseContext);
	const {icon} = useContext(IconContext)

	const isActive = (e: string): boolean => {
		return location.pathname.endsWith(e)
	}

	const linkElement = (link: string, name: string, icon: JSX.Element): JSX.Element =>
		<Link to={link}>
			<Box className={cx(classes.link, isActive(link) ? classes.activeLink : undefined )}>{icon}{name}</Box>
		</Link>

	return (
		<Box className={classes.sideMenu}>
			<Drawer
				className={classes.drawer}
				variant="permanent"
			>
				<Box className={classes.header}>
					<Typography className={classes.companyName}>{(pageBaseContext.ownedCohort && pageBaseContext.ownedCohort?.title) ?? ".."}</Typography>
					<Typography className={classes.subtitle}>Content manager</Typography>
				</Box>
				<Box className={classes.links}>
					{linkElement("courses/create", "Create course", icon("moduleIcon"))}{/* to do: update icon */}
					<Typography className={classes.sectionTitle}>MANAGEMENT</Typography>
					{linkElement("users", "Users", icon("moduleIcon"))}{/* to do: update icon */}
					{linkElement("courses", "Courses", icon("moduleIcon"))} {/* to do: update icon */}
					{linkElement("lessons", "Lessons", icon("moduleIcon"))} {/* to do: update icon */}
					{linkElement("quizzes", "Quizzes", icon("moduleIcon"))} {/* to do: update icon */}
					{/*{linkElement("reports", "Reports", <IconAdminReports />)} */}

					<Typography className={classes.sectionTitle}>GENERAL</Typography>
					{/* {linkElement("support", "Support", <IconAdminSupport />)} */}
					{linkElement("settings", "Settings", icon("moduleIcon"))} {/* to do: update icon */}
				</Box>
			</Drawer>
		</Box>
	);
}