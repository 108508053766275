import {SurveyDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {Button} from "@mui/material";
import {DynamicContainer, ModuleNotAvailable} from "@plumeuk/shapeshift-common/v2";
import {SurveyRouter} from "./SurveyRouter";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {getModule} from "@plumeuk/shapeshift-common/common";

const useStyles = makeStyles()((theme) => ({
	goToNextLesson: {
		marginTop: theme.spacing(3),
		marginLeft: "auto",
		marginRight: "auto"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0
		}
	}
}));

export const SurveyPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: survey} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);
	const navigate = useNavigate();
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();

	useEffect(() => {
		if(courseSlug && survey && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, survey, curriculum])

	return (
		<DynamicContainer noGutter={true} size="constrained" align="center">
			<SurveyDataProvider courseSlug={courseSlug} surveySlug={survey} curriculum={curriculum}>
				{({survey, apiResult}) => <>
					{apiResult.statusCode === 200 &&
						<SurveyRouter survey={survey}/>
					}
					{apiResult.statusCode === 403 &&
						<>
							<ModuleNotAvailable>
								{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
							</ModuleNotAvailable>
							{firstAvailableModule &&
							<Button
								size="large"
								variant="outlined"
								className={classes.goToNextLesson}
								onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
							>
								Go to next available lesson
							</Button>}
							{firstAvailableModule === false && <Button
								className={classes.goToNextLesson}
								onClick={() => navigate("/my-courses")}
							>
								Go back to your courses
							</Button>}
						</>
					}
				</>
				}
			</SurveyDataProvider>
		</DynamicContainer>
	);
}