import {useNavigate} from "react-router-dom";
import {DynamicContainer, NotesLibrary} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	pageBase: {}
}));

export const NotesPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<DynamicContainer size="wide">
				<NotesLibrary onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}}/>
			</DynamicContainer>
		</PageBase>
	);
}