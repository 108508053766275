import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminHeader, AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {HeadCell} from "@plumeuk/shapeshift-common/admin/table/EnhancedTableHead";
import {GridColDef} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((_theme) => ({
	adminUsersList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells: AdminTableProps<"users", IAdminUsersResponseEntry>["columns"] = ([
	{
		field: "firstname",
		flex: 1,
		headerName: "First Name",
		width: 200,
		type: "string"
	},
	{
		field: "lastname",
		flex: 1,
		headerName: "Last Name",
		width: 200,
		type: "string"
	},
	{
		field: "email",
		flex: 1,
		headerName: "Email",
		width: 200,
		type: "string"
	},
	{
		field: "createdAt",
		flex: 1,
		headerName: "Created",
		filterable: true,
		width: 100,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminUsersList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	// const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminUsersList}>
			<AdminHeader
				title="Users"
				subtitle="Manage users, enrol them into users and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"users", IAdminUsersResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/users"
				columns={headCells}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete
				type="users"
			/>
		</Box>
	);
}